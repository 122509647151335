import React from 'react';
import { useAccounting } from "../../hooks";
import './DonutChart.css';

export default function DonutChart({ 
  labelColor = '#333333', 
  labelDistance = 1.3,
  fontSize = 12,
  data = [],
  total = 0,
  amount = 0,
}) {
  // Ejemplo de data para el gráfico
  // [
  //   { label: 'RED DE SEGURIDAD', value: 44.1, color: '#a89d7f', labelDistance: 1.4 },
  //   { label: 'OMINT', value: 25.1, color: '#cbc4b0', labelDistance: 1.2 },
  //   { label: 'SUBSIDIO DE SALUD', value: 14.1, color: '#e6e3d9', labelDistance: 1.5 },
  //   { label: 'COLMED', value: 32.1, color: '#b9af96', labelDistance: 1.3 }
  // ]
  const { formatedCurrency } = useAccounting()
  let currentAngle = 0;
  const radius = 80;
  const innerRadius = radius * 0.52; // Más gruesa

  // Aumentamos significativamente el viewBox para asegurar que todos los textos sean visibles
  const viewBoxSize = 320;
  const viewBoxOffset = viewBoxSize / 2;

  const splitLabel = (label) => {
    const words = label.split(' ');
    if (words.length <= 2) return [label];
    return [words.slice(0, 2).join(' '), words.slice(2).join(' ')];
  };

  return (
    <div className="chart-container">
      <svg className="donut-chart" viewBox={`${-viewBoxOffset} ${-viewBoxOffset} ${viewBoxSize} ${viewBoxSize}`}>
        {data.map((item, index) => {
          const angle = (item.value / total) * 360;
          const midAngle = currentAngle + (angle / 2);
          const x1 = Math.cos(currentAngle * Math.PI / 180) * radius;
          const y1 = Math.sin(currentAngle * Math.PI / 180) * radius;
          const x2 = Math.cos((currentAngle + angle) * Math.PI / 180) * radius;
          const y2 = Math.sin((currentAngle + angle) * Math.PI / 180) * radius;
          
          const largeArcFlag = angle > 180 ? 1 : 0;
          const pathData = `
            M ${x1 * innerRadius/radius} ${y1 * innerRadius/radius}
            L ${x1} ${y1} 
            A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} 
            L ${x2 * innerRadius/radius} ${y2 * innerRadius/radius}
            A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${x1 * innerRadius/radius} ${y1 * innerRadius/radius}
            Z
          `;

          // Label positioning
          const labelRadius = radius * (item.labelDistance || labelDistance);
          const labelX = Math.cos(midAngle * Math.PI / 180) * labelRadius;
          const labelY = Math.sin(midAngle * Math.PI / 180) * labelRadius;

          currentAngle += angle;

          const labelLines = splitLabel(item.label);

          return (
            <g key={index} className="chart-slice">
              <path d={pathData} fill={item.color} />
              <text
                x={labelX}
                y={labelY}
                className="chart-label"
                textAnchor={labelX > 0 ? "start" : "end"}
                dominantBaseline="middle"
                fontSize={fontSize}
                fill={labelColor}
              >
                {labelLines.map((line, i) => (
                  <tspan 
                    fill="#777676"
                    fontWeight="bold"
                    style={{fontSize:'12px'}}
                    key={i} 
                    x={labelX} 
                    dy={i === 0 ? `-${fontSize / 10}em` : `${fontSize / 10}em`}
                  >
                    {line}
                  </tspan>
                ))}
                <tspan style={{fontSize:'18px'}} x={labelX} dy={`${fontSize / 8}em`} fontWeight="bold">{`${item.percentage} %`}</tspan>
              </text>
            </g>
          );
        })}
      </svg>

      <div className="chart-info">
        <div className="chart-info-title">ORDENES: <span>{amount}</span></div>
        <div className='line'></div>
        <div className="chart-info-value">MONTO: <span>{formatedCurrency(total)}</span></div>
      </div>
    </div>
  );
}
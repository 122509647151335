/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import './SignUp.css';

// libraries
import { Button } from 'antd-mobile';
import { withRouter } from 'react-router';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { sendEmailVerification, createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';

// constants
import { PROFESSIONS, REGISTRATION, PROVINCE, MEDICCATEGORY } from '../../constants/mock-data';
import texts from '../../constants/texts';

// utils
import { db } from '../../base';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/provider';
//import { OrganizationsManager } from '../../components/OrganizationsManager/OrganizationsManager';


//const MAX_STEPS = 2;


const initialState = {
  name: '',
  cuil: '',
  email: '',
  numOrden: '00000000',
  associateNumber: '',
  medicalLicence: '',
  registration: '',
  province: '',
  profession: '',
  speciality: '',
  phone: '',
  isNotColleged: true,
  //selectedOrganizations: [],
  subscriptionIsActive: false,
};

const CustomSelect = ({ options, setSelectedOpt, fieldName }) => {
  return (
    <>
      <select name='empty'
        onChange={(e) => setSelectedOpt(e.target.value)}>
        <option hidden> seleccione una opción </option>
        {options.map((opt) => (
          <option
            key={opt.id}
            className='form-field__input'
            value={opt.name}
            name={fieldName}
          >
            {opt.name}
          </option>
        ))}
      </select>
    </>

  );
};

const SignUp = ({ history }) => {

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  //const { register, handleSubmit } = useForm()
  const [state] = useContext(AppContext);
  const { userGoogle } = state;
  const [formStep, setFormStep] = useState(1);
  const [userInfo, setUserInfo] = useState(initialState);
  const [selectedProf, setSelectedProf] = useState(null);
  const [selectedProv, setSelectedProv] = useState(null);
  const [selectedReg, setSelectedReg] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSpec, setSelectedSpec] = useState(null);
  const [messageError, setMessageError] = useState(false);
  const [checked, setChecked] = useState(initialState.isNotColleged);
  //const [organizations, setOrganizations] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [psicoSpecialities, setPsicoSpecialities] = useState([]);
  const {
    pages: {
      signup: {
        signupTitle,
        form: {
          nameText,
          cuilCuitText,
          emailText,
          passwordText,
          medicalLicenceText,
          professionText,
          provinceText,
          specialityText,
          phoneText,
          phonePlaceholder,
          signupTextSubmit,
          loginLinkText,
          collegeText,
          registrationText,
          categoryText,
          associateNumberText
        },
      },
    },
  } = texts;

  //Hacer validaciones de todos los inputs
  //const jumpNextStep = () => { setFormStep(formStep + 1) };

  const jumpPrevStep = () => { setFormStep(formStep - 1) };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  // const addSelectedOrganization = organization => {
  //   setUserInfo({
  //     ...userInfo,
  //     selectedOrganizations: [...userInfo.selectedOrganizations, {
  //       name: organization.name,
  //       id: organization.id,
  //       providerNumber: '',
  //     }],
  //   });
  // };

  // const updateProviderNumber = (name, value) => {
  //   const lastPosition = userInfo.selectedOrganizations.length - 1;

  //   const updatedSelectedOrganizations = [...userInfo.selectedOrganizations, {
  //     name: userInfo.selectedOrganizations[lastPosition].name,
  //     id: userInfo.selectedOrganizations[lastPosition].id,
  //     [name]: value,
  //   }];

  //   const penultimatePosition = updatedSelectedOrganizations.length - 2
  //   const curatedSelectedOrganizations =
  //     updatedSelectedOrganizations
  //       .slice(0, penultimatePosition)
  //       .concat(updatedSelectedOrganizations.slice(penultimatePosition + 1, updatedSelectedOrganizations.length))

  //   setUserInfo({
  //     ...userInfo,
  //     selectedOrganizations: curatedSelectedOrganizations
  //   });
  // };

  const handleSelect = () => {
    setUserInfo({
      ...userInfo,
      profession: selectedProf,
      speciality: selectedSpec,
      category: selectedCategory,
      province: selectedProv,
      registration: selectedReg,
      type: 'medic'
    });
  };

  useEffect(() => {
    handleSelect();
  }, [selectedProf, selectedSpec, selectedProv, selectedReg, selectedCategory]);

  useEffect(() => {
    register('name');
    register('email');
    register('phone');
    
  }, [register]);

  // Establecer valores iniciales cuando el usuario de Google esté disponible
  useEffect(() => {
    if (userGoogle) {
      setValue('name', userGoogle?.name ?? '');
      setValue('email', userGoogle?.email ?? '');
      setValue('phone', userGoogle?.phone ?? '');
    }
  }, [userGoogle, setValue]);

  const handleSaveUser = useCallback(
    async (user) => {
      try {
        await setDoc(doc(db, 'users', user.uid), {
          ...userInfo,
        });
        Swal.fire({
          title: 'Usuario creado con exito',
          text: 'Un correo de verificacion fue enviado a ' + user.email,
          icon: 'success',
          confirmButtonText: 'Ok',
        });

        history.push('/login');
      } catch (error) {
        Swal.fire({
          title: `Error ${error.code}`,
          text: 'No se pudo crear el usuario: ' + error.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      };
    },
    [history, userInfo]
  );

  const handleSignUp =
    async (data) => {
      const { email, password } = data;
      try {
        const auth = getAuth();
        await createUserWithEmailAndPassword(auth, email, password)
          .then((userCredencial) => {
            const user = userCredencial.user;
            handleSaveUser(user);
            sendEmailVerification(user)
              .then(() => {
                // Email verification sent!
                console.log('Un link de verificacion fue enviado a ' + user.email)
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                throw new Error(`Error ${errorCode}: ${errorMessage}`);
              });
          });
      } catch (error) {
        let errorText;
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorText = 'El email ya existe.';
            break;
          case "auth/invalid-email":
            errorText = 'Formato de email no valido.'
            break;
          case "auth/weak-password":
            errorText = 'Contraseña débil.';
            break;
          default:
            errorText = 'Ocurrió un error.';
            break;
        };
        Swal.fire({
          title: 'Error al crear el usuario',
          text: errorText,
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then(_ => {
          jumpPrevStep();
        });
      };
    };

  const fetchData = useCallback(async () => {
    try {
      let organizations = [];
      let specialities = [];
      let psicoSpecialities = [];

      const specialitiesRef = collection(db, 'specialities');
      const specialitiesSnapshot = await getDocs(specialitiesRef);
      specialitiesSnapshot.forEach((doc) => {
        specialities.push({ ...doc.data(), id: doc.id });
        if (doc.data().isPsychologistTech)
          psicoSpecialities.push({ ...doc.data(), id: doc.id });
      });

      const organizationsRef = collection(db, 'organizations');
      const organizationsSnapshot = await getDocs(organizationsRef);
      organizationsSnapshot.forEach((doc) => {
        organizations.push({ ...doc.data(), id: doc.id });
      });

      //setOrganizations(organizations); 27/06
      setSpecialities(specialities);
      setPsicoSpecialities(psicoSpecialities);
    } catch (error) {
      console.log(error);
    }
  }, [setSpecialities]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = (data) => {
    if ((selectedProf === null || selectedProf === '') || (selectedProv === null || selectedProv === '') || (selectedReg === null || selectedReg === '')) {
      setMessageError(true);

    } else {
      handleSignUp(data);
    }
  };

  return (
    <div className='page-container'>
      <div className='form-container-signup'>
        <div className='form-container__logo'>
          <Logo className='logo' />
        </div>
        <div>
          <h2>
            {signupTitle}
          </h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <section >
            <div className='form-field'>
              <label>{nameText}</label>
              <input
                {...register('name', {
                  required: true,
                })}
                name='name'
                type='text'
                placeholder='Nombre y apellido completo'
                className='form-field__input'
                onChange={handleInputChange}
              />
              {errors.name && <p className='form-field__error'>Debe ingresar su nombre completo</p>}
            </div>
            <div className='form-field'>
              <label>{cuilCuitText}</label>
              <input
                {...register('cuil', {
                  required: {
                    value: true,
                    message: 'Debe ingresar su CUIL',
                  }
                })}
                name='cuil'
                type='number'
                className='form-field__input'
                placeholder='Solo escriba números, sin guiones ni puntos'
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
              />
              <p className='form-field__error'>{errors.cuil?.message}</p>
            </div>
            <div className='form-field'>
              <label>{emailText}</label>
              <input
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Debe ingresar un email',
                  }
                })}
                name='email'
                type='email'
                className='form-field__input'
                placeholder='ejemplo@ejemplo.com'
                onChange={handleInputChange}
              />
              <p className='form-field__error'>{errors.email?.message}</p>
            </div>
            <div className='form-field'>
              <label>{passwordText}</label>
              <input
                {...register('password', {
                  required: 'Debe ingresar una contraseña',
                })}
                name='password'
                type='password'
                className='form-field__input'
                autoComplete='new-password'
              />
              <p className='form-field__error'>{errors.password?.message}</p>
            </div>
            <div className='form-field'>
              <label>{medicalLicenceText}</label>
              <input
                {...register('medicalLicence', {
                  required: 'Debe ingresar una matricula profesional',
                })}
                name='medicalLicence'
                type='number'
                placeholder='Ingrese su número de matricula profesional'
                className='form-field__input'
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
              />
              <p className='form-field__error'>
                {errors.medicalLicence?.message}
              </p>
            </div>
            <div className='form-field'>
              <label>{registrationText}</label>
              <CustomSelect
                options={REGISTRATION}
                setSelectedOpt={setSelectedReg}
                fieldName='registration'
              />
              {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
            </div>
            <div className='form-field'>
              <label>{categoryText}</label>
              <CustomSelect
                options={MEDICCATEGORY}
                setSelectedOpt={setSelectedCategory}
                fieldName='category'
              />
              {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
            </div>
            <div className='form-field'>
              <label>{provinceText}</label>
              <CustomSelect
                options={PROVINCE}
                setSelectedOpt={setSelectedProv}
                fieldName='province'
              />
              {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
            </div>
            <div className='form-field'>
              <label>{professionText}</label>
              <CustomSelect
                options={PROFESSIONS}
                setSelectedOpt={setSelectedProf}
                fieldName='profession'
              />
              {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
            </div>
            {selectedProf && specialities.length !== 0 && (
              <div className='form-field'>
                <label>{specialityText}</label>
                <CustomSelect
                  options={
                    selectedProf === 'Psicología' ? psicoSpecialities : specialities
                  }
                  setSelectedOpt={setSelectedSpec}
                  fieldName='speciality'
                />
                {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
              </div>
            )}
            <div className='form-field'>
              <label>{phoneText}</label>
              <input
                {...register('phone', {
                  required: 'Debe ingresar un telefono',
                })}
                name='phone'
                type='number'
                placeholder={phonePlaceholder}
                className='form-field__input'
                onChange={handleInputChange}
              />
              <p className='form-field__error'>{errors.phone?.message}</p>
            </div>
            <div className='form-field' style={{ flexDirection: 'row' }}>
              <label> {collegeText} </label>
              <div className='form-field__radio'>
                <input
                  type='checkbox'
                  checked={checked}
                  onChange={e => {
                    setChecked(!checked)
                    setUserInfo({ ...userInfo, isNotColleged: e.target.checked })
                  }}
                />
              </div>
            </div>
            <div className='form-field'>
              <label>{associateNumberText}</label>
              <input
                {...register('associateNumber', {
                  maxLength: {
                    value: 5,
                    message: 'El número de socio no puede exeder los 5 caracteres'
                  },
                  required: 'Debe ingresar su número de socio'
                })}
                name='associateNumber'
                type='number'
                placeholder='Ingrese su número de asociado'
                className='form-field__input'
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
              />
              <p className='form-field__error'>
                {errors.associateNumber?.message}
              </p>
            </div>
            {/* <div className='form-field'>
            <OrganizationsManager
              organizations={organizations}
              addSelectedOrganization={addSelectedOrganization}
              updateProviderNumber={updateProviderNumber}
              selectedOrganizations={userInfo.selectedOrganizations}
            />
          </div> */}
            <Button
              type='submit'
              block
              color='success'
            // onClick={onSubmitFormStep1}
            >
              {signupTextSubmit}
            </Button>
          </section>
        </form>
        <Link className='link' style={{ marginBottom: '2rem' }} to='/login'>
          {loginLinkText}
        </Link>
      </div >
    </div>
  );
};

export default withRouter(SignUp);

import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../base";

   const useOrganizations = () => {
    const [organizations, setOrganizations] = useState([]);
  
    useEffect(() => {
      const fetchOrganizations = async () => {
        const organizationsCollection = collection(db, 'organizations');
        const organizationsSnapshot = await getDocs(organizationsCollection);
        const organizationsData = organizationsSnapshot.docs.map((doc) => doc.data());
        setOrganizations(organizationsData);
      };
  
      fetchOrganizations();
    }, []);
  
    return organizations;
};
  
export default useOrganizations
import React, { useState, useEffect, useContext } from 'react'
import "./floatingPanel.css";
import { Dropdown } from 'antd-mobile'
import { DownOutline, UpOutline } from 'antd-mobile-icons'
import { AppContext } from "../../context/provider";
import { useAccounting } from "../../hooks";
import Logo from '../../assets/logo192.png'

export const PanelAnimate = () =>  {
  const [state, setState] = useContext(AppContext);
  // const history = useHistory();
  const { formatedCurrency } = useAccounting()
  const { currentUser, active, totalCancelled, totalActive, claimResponsesSummary } = state;
  const [isOpen, setIsOpen] = useState(false);
  // const { id } = currentUser;
  const total = formatedCurrency(totalActive + totalCancelled);
  const handleDropdownChange = () => {
    if(claimResponsesSummary.length === 0){
      getProcessedSummary();
    }
    setIsOpen(state => !state);
  };

  // Move this to a hook outside of the component
  const getProcessedSummary = async () => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getMonth() - 1); // Fecha de hace un mes

    // const path = `users/${id}/claim-responses`;
    // const claimResponsesRef = collection(db, path);
    // const q = query(
    //   claimResponsesRef,
    //   where("serviceDate", "<", oneMonthAgo.toISOString()),
    //   orderBy("serviceDate", "desc")
    // );
    // const querySnapshot = await getDocs(q);

    const groupedResult = {};

    active.forEach((data) => {
      const serviceDate = new Date(data.serviceDate.seconds * 1000);
      const plus = data.plus || 0;

      const year = serviceDate.getFullYear();
      const month = serviceDate.getMonth();

      const today = new Date();
      const isToday = serviceDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);

      const dayName = serviceDate.toLocaleDateString("es-ES", { weekday: "long" }).toUpperCase();
      const day = isToday ? 'HOY' : `${dayName.charAt(0).toUpperCase()}${dayName.slice(1)} ${serviceDate.getDate()}`;
      const monthName = new Date(year, month).toLocaleString("es-ES", { month: "long"}).toUpperCase();

      const monthNumber = String(month + 1).padStart(2, '0'); // Mes comienza desde 0
      const dayNumber = String(serviceDate.getDate()).padStart(2, '0'); // Día comienza desde 1
      const formatedServiceDate = `${year}-${monthNumber}-${dayNumber}`;

      if (!groupedResult[monthName]) {
        groupedResult[monthName] = {};
      }

      if (!groupedResult[monthName][day]) {
        groupedResult[monthName][day] = [];
      }
      if (!groupedResult[monthName][day].totalPlus) {
        groupedResult[monthName][day].totalPlus = 0;
      }

      if (!groupedResult[monthName][day].consultations) {
        groupedResult[monthName][day].consultations = 0;
      }

      groupedResult[monthName][day].totalPlus += plus;
      groupedResult[monthName][day].consultations += 1
      groupedResult[monthName][day].serviceDate = formatedServiceDate;
    });

    const result = Object.entries(groupedResult).map(([month, days]) => ({
      month,
      items: Object.entries(days).map(([day, { totalPlus, consultations, serviceDate }]) => ({
        day,
        totalPlus,
        consultations,
        serviceDate
      })).sort( (a, b) => new Date(a.serviceDate) - new Date(b.serviceDate)).reverse(),
    }));

    setState({ ...state, claimResponsesSummary: result });
   };

  useEffect(() => {
    if(claimResponsesSummary.length === 0){
      getProcessedSummary();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={`panel ${isOpen ? '' : 'prueba'}`}>
      <Dropdown onChange={handleDropdownChange}>
        <Dropdown.Item 
          key='more' 
          title={
            <div className="dropdown-title">
              <span className="total-amount">{total}</span>
              <span className="to-collect">a cobrar</span>
              <span className="dropdown-arrow">
              {isOpen ? <UpOutline className="arrow-icon up" /> : <DownOutline className="arrow-icon down" />}
              </span>
            </div>
          }
          className={isOpen ? 'dropdown-open' : ''}
        >
          {claimResponsesSummary.map((response, index) => (
            <div className='panel_container panel-color no-top-padding' key={index}>
              <span className="month-title">{response.month}</span>
              <ul className="dropdown-list">
                {response.items.map((item, index) => (
                  <a style={{'textDecoration': 'none'}} href={`/processed-summary/${currentUser.id}/${item.serviceDate}`} key={index}>
                    <li className="dropdown-item" style={{'cursor': 'pointer'}}>
                      <img className="logo-image" src={Logo} alt="Logo" />
                      <div className="dropdown-item-list">
                        <span className="date">{item.day}</span>
                        <div style={{'display':'flex', 'alignItems':'center','gap':'16px'}}>
                          <span style={{'fontWeight': 'bold'}} className="amount-panel">{item.consultations}</span>
                          <span style={{'width':'55px'}} className="amount-panel">{formatedCurrency(item.totalPlus)}</span>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
            </div>
          ))}
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
}
import React from "react";
import Header from "../../layout/Header/Header";
import { Search } from "antd-mobile";
import { AddCircleOutline } from "antd-mobile-icons";
import "./DetalleObraSocial.css";

function DetalleObraSocial() {
  const pacientes = [
    { nombre: "PAULA SOLIS", orden: "CONSULTA", monto: "$19.492,00" },
    { nombre: "ELEONORA SÁNCHEZ", orden: "CONSULTA", monto: "$340,00" },
    { nombre: "DIEGO JUAREZ", orden: "CONSULTA", monto: "$340,00" },
  ];

  return (
    <div className="summary-container">
      <Header title={"Procesado"} isSubscripter={true} />
      <nav
        style={{
          background: "#a89d7f",
          height: "60px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "20px",
          padding: "10px 20px",
        }}
      >
        <Search style={{ width: "100%" }} />
        <AddCircleOutline style={{ fontSize: "40px", color: "white" }} />
      </nav>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 40px 20px",
        }}
      >
        <p
          style={{
            margin: "0",
            fontWeight: "bold",
            color: "#2AA726",
            fontSize: "16px",
          }}
        >
          MARZO
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "end",
          }}
        >
          <p style={{ margin: "0", color: "#777676", lineHeight: "1" }}>
            JUEVES 5
          </p>
          <p style={{ margin: "0", color: "#777676" }}>$23.423</p>
        </div>
      </div>

      <div>
        <h1
          style={{
            padding: "0 20px",
            fontWeight: "bold",
            color: "#a89d7f",
            marginBottom: "0px",
            fontSize: "20px",
          }}
        >
          COLMED
        </h1>

        <div className="tabla-pacientes">
          <div className="tabla-header">
            <div className="columna" style={{ flex: "2" }}>
              PACIENTE
            </div>
            <div
              className="columna align-right"
              style={{ color: "rgb(119, 118, 118)" }}
            >
              ORDENES
            </div>
            <div
              className="columna align-right"
              style={{ color: "rgb(119, 118, 118)" }}
            >
              MONTO
            </div>
          </div>
          {pacientes.map((paciente, index) => (
            <div
              key={index}
              className="tabla-row"
              style={{ color: "rgb(119, 118, 118)" }}
            >
              <div className="columna paciente-nombre" style={{ flex: "2" }}>
                <a style={{'textDecoration': 'none'}} href="/patient-detail">{paciente.nombre}</a>
              </div>
              <div className="columna align-right">{paciente.orden}</div>
              <div className="columna align-right">{paciente.monto}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DetalleObraSocial;

import React, { useState, useEffect, useContext } from "react";
import "./Home.css";
import { Space } from "antd-mobile";
import { AppContext } from '../../context/provider';
import Header from '../../layout/Header/Header';
import { useFirestore } from '../../hooks';
import { AuthClaimManager, PanelAnimate, PatientManager } from '../../components';
// import GetTurnos from "../../components/turnos/GetTurnos";
import { Button } from "@mui/material";
import { useHistory } from 'react-router-dom';
import TurnosHome from "../../components/TurnosHome/TurnosHome";

const Home = () => {
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [state] = useContext(AppContext);
  const history = useHistory();
  const { loadData } = useFirestore()
  const { currentUser: { name } } = state;
  const { selectedPatient } = state;

  const turnos = [
    { nombre: 'Rodriguez, Juan', hora: '12:00 am' },
    { nombre: 'Gonzalez, Laura', hora: '13:00 pm' },
  ];

  const getStoredCurrentUser = () => {
    try {
      const currentUserLogued = localStorage.getItem('storedCurrentUser');
      return currentUserLogued ? JSON.parse(currentUserLogued) : null;
    } catch (error) {
      console.error(error);
    }
  };
  
  
  // console.log('state',JSON.stringify(state, null, 5));

  const startUser = () => {
    const user = getStoredCurrentUser();
    if (!user) return;
    loadData(user);
  };

  const buildName = () => {
    
    return name ? `Dr ${name}` : "Cargando...";
  };

  useEffect(() => {
    startUser();
    buildName();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickHDC =()=>{
    history.push('/medical-record');
  }

  return (
    <>
      <Header title={buildName()} noback={true} isHome={true} />
      <Space align='center' direction='vertical' className=' animate__animated animate__fadeIn animate__faster'>
        {isPanelVisible && <PanelAnimate /> /**este componente muestra la caja $$$ */} 
        <div style={{padding: "0px 20px"}}>
          <PatientManager /> {/*gestiona la busqueda de pacientes*/}
        </div>
        <div style={{padding: "0px 20px"}}>
          <AuthClaimManager setIsPanelVisible={setIsPanelVisible} /> {/*gestiona la mueatra de tarjetas verdes con las prestaciones*/}
        </div>
      </Space>
        {selectedPatient?.name && (
          <div style={{padding: '10px 20px', width:'100%'}}>
            <Button style={{width:'100%', borderRadius: '5px'}} variant="outlined" color="success" onClick={handleClickHDC}>
              HISTORIA CLINICA
            </Button>
          </div>
        ) /*si hay un paciente gestionar HC*/}
      {/* <GetTurnos /> */}

      <TurnosHome turnos={turnos} />

      <div className="bottom-border"></div>
    </>
  );
};

export default Home;

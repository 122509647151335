/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "./EditPatient.css";

// libraries
import { Button, Divider, Space } from "antd-mobile";
import { withRouter } from "react-router";
import { useForm } from 'react-hook-form';
import { doc, setDoc } from "@firebase/firestore";
import Swal from 'sweetalert2';

// utils
import { db } from "../../base";
import { AppContext } from '../../context/provider';

// components
import Header from '../../layout/Header/Header';

// constant
import texts from '../../constants/texts';
import { Grid } from "@mui/material";

const CustomSelect = ({ options, setSelectedOpt, fieldName, defaultOpt }) => {
  return (
    <select
      style={{color: 'rgba(0, 0, 0, 0.732)', border: '1px solid #cccccc', padding: '12px'}}
      name='empty'
      onChange={(e) => setSelectedOpt(options.filter(opt => opt.name === e.target.value)[0])}
      value={defaultOpt && defaultOpt.name}
      multiple={false}
    >
      <option hidden> seleccione una opción </option>
      {options.map(opt => (
        <option
          key={opt.id}
          className='form-field__input'
          value={opt.name}
          name={fieldName}
        >
          {opt.name}
        </option>
      ))}
    </select>
  );
};

const EditPatient = ({ history }) => {

 // Estado para almacenar la imagen cargada
 const [profileImage, setProfileImage] = useState(null);

 // Función para manejar la carga de imagen
 const handleImageUpload = (event) => {
   const file = event.target.files[0];
   if (file) {
     // Crear una URL temporal para previsualizar la imagen cargada
     const imageUrl = URL.createObjectURL(file);
     setProfileImage(imageUrl);
   }
 };


  const { register, handleSubmit } = useForm();
  const [state, setState] = useContext(AppContext);
  const { selectedPatient } = state;
  const [userInfo, setUserInfo] = useState(selectedPatient);
  const { pages: { editPatient: {
    title,
    form: {
      nameLabel,
      affiliateLabel,
      dniLabel,
      addressLabel,
      localityLabel,
      provinceLabel,
      phoneLabel,
      phonePlaceholder,
      emailLabel,
      genderLabel,
      bloodgroupLabel,
      /* medicalrecordText, */
      saveText,
      selectOptions: {
        genders,
        bloodTypes,
      }
    },
  } } } = texts;
  const [selectedGender, setSelectedGender] = useState(/* selectedPatient.gender || null */ "");
  const [selectedBloodType, setSelectedBloodType] = useState(/* selectedPatient.bloodType || null */ "");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSelect = () => {
    setUserInfo({
      ...userInfo,
      gender: selectedGender,
      bloodType: selectedBloodType
    });
  };

  useEffect(() => {
    handleSelect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGender, selectedBloodType]);

  // useEffect(() => {
  //   setOrganizations(state.organizations);
  // }, []);

  const handleSetPatient = async _ => {
    const patiendRef = doc(db, "patients", userInfo.dni);
    await setDoc(patiendRef, { ...userInfo, gender: selectedGender, bloodType: selectedBloodType });

    Swal.fire({
      title: 'Usuario modificado con exito',
      icon: 'success',
      confirmButtonText: 'Ok',
    });


    setState({ ...state, selectedPatient: userInfo });

    history.push('/')
  };

  const onSubmit = _ => handleSetPatient();

  // const updateSelectedOrganization = organization => {
  //   setUserInfo({
  //     ...userInfo,
  //     organization,
  //   });
  // };

  // const handleSelectOrg = e => {
  //   const organizationId = e.target.value;
  //   const organization = organizations.find(
  //     organization => organization.id === organizationId
  //   );
  //   updateSelectedOrganization(organization.name);
  // };

  // const SelectOrganization = () => {
  //   return (
  //     <select className='organizations' onChange={handleSelectOrg}>
  //       <option hidden> {userInfo && userInfo.organization} </option>
  //       {organizations.map((organization) => (
  //         <option key={organization.id} value={organization.id}>
  //           {organization.name}
  //         </option>
  //       ))}
  //     </select>
  //   );
  // };

  return (
    <Grid container style={{'background': '#e8e8e8'}}>
      <Header title={title} />
      <Space style={{ padding: '0 20px'}} />
        <Grid item xs={12} md={3} sx={{ backgroundColor: '#e8e8e8' }}>
        </Grid>
        <Grid item xs={12} md={6} sx={{ backgroundColor: '#e8e8e8', padding: '0 20px' }}>
          <div style={{'color': '#212224'}} className="form-title"> Nuevo Paciente </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            {/* Mostrar la imagen cargada o una inicial con una letra */}
            <div style={{
              width: '100px',
              height: '100px',
              backgroundColor: '#D32F2F',
              borderRadius: '50%',
              color: '#fff',
              fontSize: '2em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '3px solid #4CAF50',
              backgroundImage: profileImage ? `url(${profileImage})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
              {!profileImage && "E"}
            </div>

            {/* Input para cargar la imagen */}
            <label htmlFor="imageUpload" style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '50%',
              padding: '5px',
              cursor: 'pointer',
            }}>
              📷
            </label>
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
          </div>
        </div>

          <form className="bg-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{nameLabel}</label>
                <input
                  /* value={userInfo.name} */
                  {...register('firstname')}
                  name="firstname"
                  type="text"
                  className="form-field__input" onChange={handleInputChange}
                  placeholder="Ingresa tu nombre"
                />
            </div>
            
            <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{nameLabel}</label>
                <input
                  /* value={userInfo.name} */
                  {...register('lastname')}
                  name="lastname"
                  type="text"
                  className="form-field__input" onChange={handleInputChange}
                  placeholder="Ingresa tu apellido"
                />
              </div>

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{affiliateLabel}</label>
                <input
                  /* value={userInfo.affiliate} */
                  {...register('affiliate')}
                  name="affiliate"
                  type="number"
                  className="form-field__input" onChange={handleInputChange}
                  placeholder="Ingresa tu número de afiliado"
                />
              </div>

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{dniLabel}</label>
                <input /* value={userInfo.dni} */ {...register('dni')} name="dni" type="number" className="form-field__input" onChange={handleInputChange} placeholder="11111111" />
              </div>
            </div>
            {/* <SelectOrganization /> */}

            {/* <div className="form-field">
              <label>{ organizationLabel }</label>
              <input
                {...register('organization')}
                //value={userInfo.organization}
                name="organization"
                type="text"
                className="form-field__input"
                onChange={handleInputChange}
              />
            </div> */}

            <div className="form-group">
              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{addressLabel}</label>
                <input
                  {...register('address')}
                  /* value={userInfo.address} */
                  name="address"
                  type="text"
                  className="form-field__input"
                  onChange={handleInputChange}
                  placeholder="123 Street 487 House"
                />
              </div>

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{localityLabel}</label>
                <input
                  {...register('locality')}
                  /* value={userInfo.locality} */
                  name="locality"
                  type="text"
                  className="form-field__input"
                  onChange={handleInputChange}
                  placeholder="Ingresa tu localidad"
                />
              </div>

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{provinceLabel}</label>
                <input
                  {...register('province')}
                  /* value={userInfo.province} */
                  name="province"
                  type="text"
                  className="form-field__input"
                  onChange={handleInputChange}
                  placeholder="Ingresa tu provincia"
                />
              </div>

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{phoneLabel}</label>
                <input
                  /* value={userInfo.phone} */
                  {...register('phone')}
                  name="phone"
                  type="number"
                  placeholder={phonePlaceholder}
                  className="form-field__input"
                  onChange={handleInputChange}

                />
              </div>
              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{emailLabel}</label>
                <input
                  /* value={userInfo.email} */
                  {...register('email')}
                  name="email"
                  type="text"
                  className="form-field__input"
                  onChange={handleInputChange}
                  placeholder="Ingresa tu email"
                />
              </div>
            </div>

            <div className="form-group">  
        

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{genderLabel}</label>
                <CustomSelect
                  options={genders}
                  setSelectedOpt={setSelectedGender}
                  fieldName='gender'
                  defaultOpt={selectedGender}
                />
              </div>

              <div className="form-field">
                <label style={{'fontSize': '14px', 'color': '#6b7575', 'fontWeight': '600'}}>{bloodgroupLabel}</label>
                <CustomSelect
                  options={bloodTypes}
                  setSelectedOpt={setSelectedBloodType}
                  fieldName='bloodType'
                  defaultOpt={selectedBloodType}
                />
              </div>
            </div>



            {/* <div className="form-field" style={{ flexDirection: 'row' }}>
              <label>{ medicalrecordText }</label>
              <Button className="form-button__medicalRecord" onClick={() => {}} color="primary"> VER </Button>
            </div> */}

            <Divider />
            <Button type="submit" block color='success'>{saveText}</Button>
          <Space />
          {/* <Button onClick={cancelEdit} block color='primary'>{ cancelText }</Button> */}
        </form>
        </Grid>

    </Grid>
  );
};

export default withRouter(EditPatient);

import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';

export const useCloudFunction = (url, params = {}) => {
  const [datos, setDatos] = useState(null);
  const [error, setError] = useState(null);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const llamarCloudFunction = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          throw new Error('Usuario no autenticado');
        }

        const idToken = await user.getIdToken();
        const queryParams = new URLSearchParams(params).toString();
        const fullUrl = `${url}?${queryParams}`;
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.statusText}`);
        }

        const data = await response.json();
        setDatos(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setCargando(false);
      }
    };

    llamarCloudFunction();
  }, [url, params]);

  return { datos, error, cargando };
};

import React from "react";
import Header from "../../layout/Header/Header";
import { Search } from "antd-mobile";
import "./DetallePaciente.css";

const DetallePaciente = () => {
  const patientData = [
    { label: "AFILIADO", value: "26958412" },
    { label: "HORARIO", value: "15:30" },
    { label: "ORDEN DE INTERNACIÓN", value: "12345" },
    { label: "SANATORIO", value: "RIVADAVIA" },
    { label: "CÓDIGO DE AUTORIZACIÓN", value: "4321" },
    { label: "INTERVENCIÓN", value: "LAPAROSCOPICA" },
    { label: "TRATAMIENTO", value: "APENDICITIS" },
    { label: "FECHA DE INTERNACIÓN", value: "03/11/2024" },
    { label: "ALTA", value: "14/11/2024" },
  ];

  return (
    <div className="patient-detail summary-container">
      <Header title={"Procesado"} isSubscripter={true} />
      <nav className="navbar">
        <Search className="search" />
        {/* <AddCircleOutline className="icon" /> */}
      </nav>

      <div className="header-container">
        <p>MARZO</p>
        <div className="day">
          <p>JUEVES 5</p>
        </div>
      </div>

      <div className="name-container">
        <h1>SOLIS, PAULA</h1>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "end",
          flexDirection: "column",
          padding: "0 20px",
        }}
      >
        <span className="organization">COLMED</span>
        <span style={{ color: "rgb(119, 118, 118)", fontSize: "18px" }}>
          $19.492
        </span>
      </div>

      <div style={{ padding: "0 20px" }}>
        <div className="line"></div>
      </div>

      <div className="tabla-pacientes">
        {patientData.map((data, index) => (
          <div className="tabla-row" style={{ color: "rgb(119, 118, 118)" }}>
            <div key={index} className="columna" style={{ flex: "2" }}>
              {data.label}
            </div>
            <div className="columna" style={{ paddingRight: "0" }}>
              {data.value}
            </div>
          </div>
        ))}

        <div
          className="tabla-row"
          style={{
            color: "rgb(119, 118, 118)",
            justifyContent: "space-between",
          }}
        >
          <div className="columna" style={{ flex: "0" }}>
            FICHA
          </div>
          <button>VER</button>
        </div>
      </div>
    </div>
  );
};

export default DetallePaciente;

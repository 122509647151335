import { useContext } from 'react';
import {
  getDocs,
  collection,
  getDoc,
  updateDoc,
  doc,
  query,
  limit,
  // where,
  
} from 'firebase/firestore';
import { AppContext } from '../context/provider.js';
import { db } from '../base.js';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

export const useFirestore = () => {
  const [state, setState] = useContext(AppContext);
  //const { selectedPatient } = state;

  //* utils
  const collectClaims = (claimStatus, arr) => {
    return arr.filter((claim) => claim.status === claimStatus);
  };

  const assignMonth = (month, presentation, actual) => {
    return actual > presentation ? month + 1 : month;
  };

  const grantWorkingDay = (date) => {
    const day = dayjs(date).day();
    if (day > 0 && day < 6) return date;
    return day === 0
      ? dayjs(date).add(1, 'day').toDate() // 0: sunday
      : dayjs(date).add(-1, 'day').toDate(); // 6: saturday
  };

  //* exports
  const loadData = async (user) => {
    // console.log('user  : ', JSON.stringify(user, null, 5))
    setState({ ...state, isLoading: true });
    const organzationsRef = collection(db, 'organizations');
    const organizationPromise = getDocs(organzationsRef);
    const claimsRef = collection(db, 'users', user.id, 'claim-responses');
    const userClaimsPromise = getDocs(claimsRef);
    const subscriptionRef = doc(db, 'users', user.id);
    const subscriptionPromise = getDoc(subscriptionRef);
    const [organizationsSnap, userClaimsSnap, subscriptionSnap] = await Promise.all([
      organizationPromise,
      userClaimsPromise,
      subscriptionPromise,
    ]);
    const organizations = organizationsSnap.docs.map((org) => ({
      id: org.id,
      ...org.data(),
    }));
    const userClaims = userClaimsSnap.docs.map((claim) => ({
      id: claim.id,
      ...claim.data(),
    }));
    const active = collectClaims('active', userClaims);
    const cancelled = collectClaims('cancelled', userClaims);
    const totalActive = active.reduce((acc, doc) => acc + doc.plus, 0);
    const totalCancelled = cancelled.reduce((acc, doc) => acc + doc.plus, 0);
    const professionClaims = await getProfessionClaims(user.profession);
    const globalVarsref = doc(db, 'configs', 'global_variables');
    const snapRefs = await getDoc(globalVarsref);
    const generalPresentationDay = snapRefs.data();
    const subscriptionIsActive = subscriptionSnap.data().subscriptionIsActive;
    setState({
      ...state,
      currentUser: { ...user, subscriptionIsActive: subscriptionIsActive },
      userClaims,
      organizations,
      active,
      cancelled,
      totalActive,
      totalCancelled,
      professionClaims,
      isLoading: false,
      generalPresentationDay
    });
  };

  const getPresentationDay = (organization) => {
    const { organizations, generalPresentationDay } = state;
    const { presentationDay } = generalPresentationDay;
    const year = dayjs().year();
    const actualDay = dayjs().date();
    const org = organizations.find(({ name }) => name === organization); //? REVIEW
    const month = dayjs().month();
    if (org) {
      const amonth = assignMonth(month, org.presentation_day, actualDay);
      const organizationDate = grantWorkingDay(
        new Date(year, amonth, org.presentation_day)
      );
      return organizationDate;
    }
    //! general day for other organizations:
    const bmonth = assignMonth(month, presentationDay, actualDay);
    const generalDate = grantWorkingDay(
      new Date(year, bmonth, presentationDay)
    );
    return generalDate;
  };

  const updateClaims = async (claims = [], status) => {
    try {
      const { currentUser } = state;
      const { id: userId } = currentUser;
      let promises = [];
      claims.map(({ product }) => {
        const presentationDay = getPresentationDay(product.organization);
        const claimRef = doc(
          db,
          'users',
          userId,
          'claim-responses',
          product.id
        );
        promises.push(updateDoc(claimRef, { status, presentationDay }));
        return null;
      });
      await Promise.all(promises);
      Swal.fire('Felicidades', 'Prestaciones presentadas', 'success');
    } catch (error) {
      Swal.fire('Oops', `Something get wrong ${error}`, 'error');
    }
  };

  // const getProfessionClaims = async (profession) => {
  //   const { currentUser } = state;
  //   const colRef = collection(db, 'nomencladorAmbulatorio')
  //   const q =
  //     profession === currentUser.profession
  //       ? query(colRef, where("obraSocial", "==", "36030"))
  //       : query(colRef);
  //   const claimsSnap = await getDocs(q);
  //   let docs = [];
  //   claimsSnap.forEach((doc) => {
  //     docs.push({ id: doc.id, data: doc.data() });
  //   });

  //   return [
  //     ...docs.filter((doc) => doc.id === profession),
  //     ...docs.filter((doc) => doc.id !== profession),
  //   ];
  // };
  const getProfessionClaims = async () => {
    
    const colRef = collection(db, 'nomencladorAmbulatorio')

    const q = query(colRef,
      //  where('especialidad', '==', state.currentUser.speciality)) no usar hasta normalizar la tabla
      limit(30));
    const claimsSnap = await getDocs(q);

    let docs = [];
    claimsSnap.forEach((doc) => {
      docs.push({ id: doc.id, data: doc.data() });
    });

    return [
      ...docs.filter((doc) => doc.id)
    ];
  };

  const checkClaimsStatus = (claims) => {
    // const { userClaims } = state;
    const active = collectClaims('active', claims);
    const cancelled = collectClaims('cancelled', claims);
    const totalActive = active.reduce((acc, element) => acc + element.amount, 0);
    const totalCancelled = cancelled.reduce((acc, element) => acc + element.amount, 0);
    setState({ ...state, active, cancelled, totalActive, totalCancelled });
  }

  return {
    loadData,
    getPresentationDay,
    updateClaims,
    getProfessionClaims,
    checkClaimsStatus,
    collectClaims,
  };
};

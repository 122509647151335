import React from 'react'
import './TurnosHome.css'
import "../PanelAnimate/floatingPanel.css";

const TurnosHome = ({ turnos }) => {
    return (
      <div className="turnos-container">
        <h2 className="turnos-title">TURNOS</h2>
        {turnos.map((turno, index) => (
          <div key={index} className="turno-item">
            <span className="turno-number">{index + 1} -</span>
            <span className="turno-name">{turno.nombre}</span>
            <span className="turno-time">{turno.hora}</span>
          </div>
        ))}
      </div>
    );
};

export default TurnosHome